import React, {Suspense} from "react";
import LoadingComponent from "./Components/loadingComponent.component";
import Redirect from "./redirect";

function Home() {
  return (
    <div className="App">
      <Suspense fallback={<LoadingComponent/>}>
        <Redirect/>
        </Suspense>
    </div>
  );
}

export default Home;