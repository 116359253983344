
const Redirect = () => {
    window.onload = function() {
        // similar behavior as clicking on a link
        window.location.href = "https://cwipedia.com";
    }
    return (<>
        <h1 className="text-center">Use : <a href="https://cwipedia.com">cwipedia.com</a></h1>
        </>)
}

 
export default Redirect;