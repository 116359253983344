import sl from "../assets/interns/sachin.png"
import ss from "../assets/interns/ss.jpeg"
import as from "../assets/interns/as.jpg"
import Footer from "./footer.component"
const Team = () =>{
    return(
        <>
        <div className="container">
        <p className="h3 ">Founder of cwipedia</p>
        <div className="card shadow bg-body rounded p-link team-profile">
                    <div className="">
                    <img src="https://media-exp1.licdn.com/dms/image/C4E03AQGWnQBLg7drgg/profile-displayphoto-shrink_200_200/0/1644326613243?e=1652918400&v=beta&t=ZhrR6wM-nSodWr-bG3v-l4dLolFH0sJYgevZf3iqisE" className="card-img-top  profile-pic" alt="Shivam Hande"/>
                    </div>
                    <div className="card-body">
                        {/* <h5 className="card-title">Shivam Hande</h5> */}
                        {/* <h6 className="card-subtitle mb-2 text-muted">Managing Director and Lead SDE</h6> */}
                        <p className="card-text">Hi there, I'm <b>Shivam Hande</b> The Founder, and currently playing the role of Lead SDE. The main aim behind <b>cwipedia</b> is to deliver free education to Maharashtra students.</p> 
                        <a aria-hidden="true" class="fa fa-envelope" href="mailto:shivam@cwipedia.in" id="devso" rel="noreferrer" target="_blank" title="mail us"></a><a aria-hidden="true" class="fa fa-instagram" href="http://instagram.com/shiv.am.h" id="devso" rel="noreferrer" target="_blank" title="follow us"></a><a aria-hidden="true" class="fa fa-twitter" href="https://twitter.com/ShivamHande" id="devso" rel="noreferrer" target="_blank" title="follow us"></a><a aria-hidden="true" class="fa fa-linkedin" href="https://www.linkedin.com/in/shivamh" id="devso" rel="noreferrer" target="_blank" title="follow us"></a><a aria-hidden="true" class="fa fa-github" href="https://github.com/0shivamh" id="devso" rel="noreferrer" target="_blank" title="Join group"></a>
                        <a aria-hidden="true" class="fa fa-youtube-play" href="https://www.youtube.com/channel/UC0VEXsNow5cAJp9H2owWc-w" id="devso" rel="noreferrer" target="_blank" title="follow us"></a>
                    </div>
                </div>
       
        <p className="h3 mt-4">Our Talented team</p>
        <div className="container text-center">
            <div className="row text-center d-flex justify-content-center row-cols-2 row-cols-lg-2 g-2 g-lg-3">
                <div className="col">
                <div className="card shadow bg-body rounded p-link team-profile">
                    <div className="d-flex justify-content-center">
                    <img src="https://media-exp1.licdn.com/dms/image/C4E03AQEtrD3JB7AOSg/profile-displayphoto-shrink_200_200/0/1641667057738?e=1652918400&v=beta&t=yPl5uVYm-3KpgWc8-K3g1NFekEwCWWvLh42Ie2gLkpo" className="card-img-top d-flex justify-content-center profile-pic" alt="Shubham Awantkar"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Shubham Awantkar</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Lead Backend Developer</h6>
                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        <a href="mailto:awantkarshubham99@gmail.com
" className="link" > <i className="fa fa-envelope" aria-hidden="true"></i> </a>
                    </div>
                </div>
                </div>
                <div className="col">
                <div className="card shadow bg-body rounded p-link team-profile">
                    <div className="d-flex justify-content-center">
                    <img src={sl} className="card-img-top d-flex justify-content-center profile-pic" alt="Sacchidanand Linge"/>
                    </div>
                    <div className="card-body ">
                        <h5 className="card-title">Sacchidanand Linge</h5>
                        <h6 className="card-subtitle mb-2 text-muted">UI UX Designer</h6>
                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        <a href="mailto:sacchidanandlinge@gmail.com
" className="link" > <i className="fa fa-envelope" aria-hidden="true"></i> </a>
                    </div>
                </div>
                </div>
                <div className="col">
                <div className="card shadow bg-body rounded p-link team-profile">
                    <div className="d-flex justify-content-center">
                    <img src={ss} className="card-img-top d-flex justify-content-center profile-pic" alt="Shweta Shinde"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Shweta Shinde</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Content Writer</h6>
                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        <a href="mailto:shweta.shinde@cwipedia.in" className="link" > <i className="fa fa-envelope" aria-hidden="true"></i> </a>
                    </div>
                </div>
                </div>
                <div className="col">
                <div className="card shadow bg-body rounded p-link team-profile">
                    <div className="d-flex justify-content-center">
                    <img src={as} className="card-img-top d-flex justify-content-center profile-pic" alt="Shweta Shinde"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Ankita Sawant</h5>
                        <h6 className="card-subtitle mb-2 text-muted">(HR) Human Resource</h6>
                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        <a href="mailto:hr@cwipedia.in" className="link" > <i className="fa fa-envelope" aria-hidden="true"></i> </a>
                    </div>
                </div>
                </div>
                <div className="col">
                <div className="card shadow bg-body rounded p-link team-profile">
                    <div className="d-flex justify-content-center">
                    <img src="https://media-exp1.licdn.com/dms/image/C5603AQFnj1SbDrKDgQ/profile-displayphoto-shrink_200_200/0/1584679936851?e=1656547200&v=beta&t=73ofOjOSywTkDKBSSSRg7Ef3pUAzmfZD26dceOPvBTc" className="card-img-top d-flex justify-content-center profile-pic" alt="Pooja Hargude"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Pooja Hargude </h5>
                        <h6 className="card-subtitle mb-2 text-muted">Lead Backend Developer</h6>
                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        <a href="mailto:poojashargude@gmail.com" className="link" > <i className="fa fa-envelope" aria-hidden="true"></i> </a>
                    </div>
                </div>
                </div>
                {/* <div className="col">

                </div>
                <div className="col">

                </div>
                <div className="col">

                </div> */}
            </div>
            </div>
            </div>
            <Footer/>
        </>
    )
}
export default Team;