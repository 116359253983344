import {Component} from "react";
class About1 extends Component {
    state = {  } 
    render() { 
        return (<>
        <div className="container p-5" >
            <p className="h4">About Us</p>
        <p>
        There is CWI in cwipedia it means Computer Science and Engineering PEDIA, It is the code of our branch. For diploma students, all that is required is study materials and guidance So, This is where cwipedia comes into the picture - A Diploma Engineering portal.
        <br></br>
        <hr></hr>
        The cwipedia is a portal of education for Diploma Engineering students. It is created to deliver free education to Maharashtra students. The portal was founded or created in 2018 to make education free. cwipedia's aimed to implement and deliver valuable education for Students. Also, We provide various software services.
        <br></br>
        <hr></hr>

        The content on cwipedia has been divided into various categories to make it easily accessible for the students. Whether you want to learn Computer Science subjects, MCQs or anything, cwipedia has covered each and everything! Even if you are looking for exam preparation material, cwipedia has a vast set of study material to learn from, that gives an idea about exams. Adding to this, it serves as a platform for students to share their knowledge via contributing options.
        <br></br>
        <hr></hr>

        <b className="h5">Some of the FAQs asked by students?</b><br/><br/>


        <b>Who is the owner of cwipedia?</b><br/>

        Shivam Hande is the founder of cwipedia. His platform cwipedia is well recognized among all the Diploma engineering students throughout all colleges in Maharashtra. Apart from cwipedia, he's doing BTech.

        <br/><br/>
        <b>Who is Shivam Hande?</b><br/>

        Shivam Hande is the founder of cwipedia. His platform cwipedia is well recognized among all the Diploma engineering students throughout all colleges in Maharashtra. Apart from cwipedia, he's doing BTech and pursuing his further education.
        <br/>
        <br/>
        <b>When was cwipedia founded?</b><br/>

        cwipedia was founded in 2018 to make education free. cwipedia's aimed to implement and deliver valuable education for Students. Also, We provide various software services.

        <br/><br/>
        <b>How Can I Contact You?</b><br/>

        If you are facing out a problem related to Website Control or Study Material, please go through the Contact Us section Otherwise we are reachable at business@cwipedia.in



        </p>
        </div>
        </>);
    }
}
 
export default About1;